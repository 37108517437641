const isNative = global && global.ErrorUtils //eslint-disable-line

const defaultFilters = isNative ? [/HTTP\w+Error/] : [
  /HTTP\w+Error/,
  /AbortError/,
  /NotSupportedError/,
  /ChunkLoadError/,
  /WeixinJSBridge/,
]

function isError(obj) {
  if (obj instanceof Error) {
    return true
  }

  if (typeof obj === 'object' && obj.message && obj.stack) {
    return true
  }

  return false
}

// 获取过滤的错误统计
export function getIgnoreErrors(originIgnore) {
  if (Array.isArray(originIgnore)) {
    return [...defaultFilters, ...originIgnore]
  }
  return defaultFilters
}

// add traceId in XMLHttpRequest __raven_xhr sentry
export function addXHRTraceid() {
  if (XMLHttpRequest) {
    const __setRequestHeader = XMLHttpRequest.prototype.setRequestHeader

    XMLHttpRequest.prototype.setRequestHeader = function setRequestHeader(header, value) {
      __setRequestHeader.call(this, header, value)

      if (header === 'x-b3-traceid') {
        if (!isNative) {
          this.traceId = value
        } else if (typeof this.__raven_xhr === 'object') {
          this.__raven_xhr.traceId = value
        }
      }
    }
  }
}

// 消费统计的错误
export function consumError(buffer, Sentry) {
  while (buffer.length) {
    const { error, level, extra } = buffer.shift()

    if (isError(error)) {
      Sentry.captureException(error)
    } else if (level === 'error') {
      Sentry.captureMessage(error, level)
    } else {
      Sentry.captureBreadcrumb({
        message: error,
        category: 'logger',
        level,
        data: extra,
      })
    }
  }
}
