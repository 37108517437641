import { getUserInfoV2 } from '@xhs/ozone-bridge'

import {
  init,
  configureScope,
  captureMessage,
  captureException,
  addBreadcrumb as captureBreadcrumb,
} from '@sentry/browser'
import {
  getIgnoreErrors, addXHRTraceid, consumError,
} from './common'

const DEFAULT_SAMPLE_RATE = 0.1

const {
  sampleRate,
  useDefaultFilters = true,
  ...restOptions
} = window.__GLOBAL_SENTRY_CONFIG__ || {}

if (useDefaultFilters) {
  restOptions.ignoreErrors = getIgnoreErrors(restOptions.ignoreErrors)
}

// add traceId in XMLHttpRequest
addXHRTraceid()

init({
  sampleRate: Number(sampleRate) || DEFAULT_SAMPLE_RATE,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'xhr' && hint && hint.xhr && hint.xhr.traceId) {
      breadcrumb.data.traceId = hint.xhr.traceId
    }
    return breadcrumb
  },
  beforeSend: (event, hint) => {
    if (hint?.originalException?.ignoreSentry) {
      return null
    }

    return event
  },
  ...restOptions,
})

configureScope(scope => {
  // capturing the user
  getUserInfoV2()
    .then(({ value }) => {
      scope.setUser({
        id: value.userToken,
        username: value.userId,
      })
    })
    .catch(() => { /* No-op */ })
})

// 将 inlineTracker 收集到的 error 发到 sentry
const buffer = window.__ERROR_TRACKER_BUFFER__ || []
consumError(buffer, { captureException, captureMessage, captureBreadcrumb })

window.sentry = {
  captureBreadcrumb,
  captureException: (exception, extra) => {
    configureScope(scope => {
      scope.setExtras(extra.extra)
    })
    captureException(exception)
  },
  captureMessage: (message, extra) => captureMessage(message, extra.level),
  configureScope,
}
const loadedEvent = new CustomEvent('__errorTrackerLoaded__')
document.dispatchEvent(loadedEvent)
